import React, { useState } from "react";
import Logo from "../assets/img/logo.svg";
import { searchInJson } from "../helpers/Helper";
import SidebarItems from "./SidebarItems";
import { useNavigate } from "react-router-dom";

const Sidebar = (props) => {
  const [searchHits, setSearchHits] = useState([]);
  const { data, setSearchTerm, searchTerm, setSelectedIndex, selectedIndex } =
    props;
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    const value = event.target.value;
    const results = data.reduce((acc, item, index) => {
      if (searchInJson(item, event.target.value)) acc.push(index);
      return acc;
    }, []);

    setSearchHits(results);
    setSearchTerm(value);
  };

  const goToNextResult = () => {
    let index = selectedIndex + 1;
    if (index > data.length - 1) index = 0;

    setSelectedIndex(index);
  };

  const goToPreviousResult = () => {
    let index = selectedIndex - 1;
    if (index < 0) index = data.length - 1;

    setSelectedIndex(index);
  };

  const isInternalExtension = (number) => {
    const modifiedNumber = number.replace(/[#*/]/g, "");
    const num = parseInt(modifiedNumber, 10);
    if (isNaN(num)) {
      console.error("Invalid number input:", number);
      return false;
    }
    return num >= 0 && num <= 999;
  };

  const getContextInfo = (body) => {
    if (body) {
      const caller = body["Caller-Caller-ID-Number"];
      const destination = body["Caller-Destination-Number"];
      const callState = body["Channel-Call-State"];
      let callDirection = "Unknown call direction";

      if (caller && destination && callState) {
        const isCallerInternal = isInternalExtension(caller);
        const isDestinationInternal = isInternalExtension(destination);

        if (isCallerInternal && isDestinationInternal) {
          callDirection = `Internal: ${caller} to ${destination}`;
        } else if (isCallerInternal) {
          callDirection = `Outgoing: ${caller} to ${destination}`;
        } else if (isDestinationInternal) {
          callDirection = `Incoming: ${destination} by ${caller}`;
        }

        return `State: ${callState} - Direction: ${callDirection}`;
      }
    }
    return "No call information.";
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="sidebar">
      <img alt="TelSmart Logo" title="TelSmart Logo" src={Logo} />
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
        className="search-input"
        disabled={!data}
      />
      <button onClick={() => setSearchTerm("")} className="clear-button">
        Clear
      </button>
      <hr />
      <div className="search-navigation">
        <button onClick={goToPreviousResult} disabled={!data}>
          &lt; Previous{" "}
        </button>
        <button onClick={goToNextResult} disabled={!data}>
          Next &gt;
        </button>
      </div>
      <SidebarItems
        data={data}
        selectedIndex={setSelectedIndex}
        searchHits={searchHits}
        getContextInfo={getContextInfo}
        setSelectedIndex={setSelectedIndex}
      />
      <button onClick={logout} className="logout">
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
