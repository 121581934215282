import React from "react";

const SidebarItems = (props) => {
  const { data, selectedIndex, searchHits, getContextInfo, setSelectedIndex } =
    props;

  return (
    <>
      {data
        ? data.map((item, index) => (
            <div
              key={index}
              className={`title ${selectedIndex === index ? "selected" : ""}
      ${searchHits.includes(index) ? "has-result" : ""}
      `}
              onClick={() => {
                setSelectedIndex(index);
              }}
            >
              {getContextInfo(item.body)}
            </div>
          ))
        : ""}
    </>
  );
};

export default SidebarItems;
