import React, { useEffect, useState } from "react";
import { getIntegrationData } from "../api/integrations";
import Sidebar from "../components/Sidebar";
import MainContent from "../components/MainContent";

const Main = (props) => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [message, setMessage] = useState("Loading");

  const fetchData = async () => {
    try {
      const data = await getIntegrationData();
      setData(data);
      setMessage("");
    } catch (e) {
      setMessage("Error: unable to load in data");
      console.error(e);
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="app-container">
      <Sidebar
        data={data}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      <MainContent
        data={data}
        selectedIndex={selectedIndex}
        searchTerm={searchTerm}
        message={message}
      />
    </div>
  );
};

export default Main;
