import Axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL + "v2/integrations_test";

const getIntegrationData = async () => {
  try {
    const response = await Axios.get(apiBaseUrl + "/get_data/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return JSON.parse(response.data.body);
  } catch (e) {
    console.error(e);
    return [];
  }
};

export { getIntegrationData };
