import React, { useState } from "react";
import CollapsibleJson from "./CollapsibleJson";
import Refresh from "../assets/icon/refresh.svg";
import Pause from "../assets/icon/pause.svg";
import Play from "../assets/icon/play.svg";

const MainContent = (props) => {
  const { data, selectedIndex, searchTerm } = props;
  const [isPaused, setIsPaused] = useState(false);

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const handleRefresh = () => {
    throw new Error("To do");
  };

  return (
    <div className="main-content">
      <div className="controls">
        <button onClick={togglePause} disabled>
          {isPaused ? (
            <>
              <img src={Play} alt="refresh-icon" /> <span>Resume</span>
            </>
          ) : (
            <>
              <img src={Pause} alt="refresh-icon" />
              <span>Pause</span>
            </>
          )}
        </button>
        <button onClick={handleRefresh} disabled>
          <img src={Refresh} alt="refresh-icon" />
          <span>Refresh</span>
        </button>
      </div>
      {!props.message ? (
        data.length ? (
          <>
            <CollapsibleJson
              data={data[selectedIndex].headers}
              highlightTerm={searchTerm}
            />
            <CollapsibleJson
              data={data[selectedIndex].body}
              highlightTerm={searchTerm}
            />
          </>
        ) : (
          "No data to render"
        )
      ) : (
        <>{props.message}</>
      )}
    </div>
  );
};

export default MainContent;
