import { createContext, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const login = useCallback(
    async (userData) => {
      localStorage.setItem("token", userData);
      navigate("/main");
    },
    [navigate]
  );

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    navigate("/", { replace: true });
  }, [navigate]);

  const value = useMemo(
    () => ({
      login,
      logout,
    }),
    [login, logout]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
