import React, { useState } from "react";
import { getAuthToken, getProfile } from "../api/login";
import { useAuth } from "../helpers/UseAuth";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [warning, setWarning] = useState("");
  const { login } = useAuth();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const resp = await getAuthToken(username, password);
    if (resp) {
      try {
        const profileResp = await getProfile(resp);
        if (profileResp.entitlements.integrations) {
          login(resp);
        } else {
          localStorage.removeItem("token");
          setWarning("User does not have the rights to use this platform");
        }
      } catch (e) {
        console.error(e);
        localStorage.removeItem("token");
      }
    } else {
      setWarning("Invalid credentials");
    }

    setUsername("");
    setPassword("");
  };

  return (
    <div className="app-container">
      <div className="modal-wrapper">
        <form onSubmit={handleSubmit} className="login">
          <div>
            <h2>Login</h2>
          </div>
          <div>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          {warning}
          <div>
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
