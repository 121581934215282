import React from "react";
import { highlightText } from "../helpers/Helper";

const CollapsibleJson = ({ data, highlightTerm }) => {
  const formattedJson = JSON.stringify(data, null, 2);
  const highlightedJson = highlightTerm
    ? highlightText(formattedJson, highlightTerm)
    : formattedJson;

  return (
    <pre className="json-data">
      <code dangerouslySetInnerHTML={{ __html: highlightedJson }}></code>
    </pre>
  );
};

export default CollapsibleJson;
