import Axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL + "v1/";

const getAuthToken = async (username, password) => {
  try {
    const response = await Axios.post(apiBaseUrl + "api-token-auth/", {
      username,
      password,
    });
    return response.data.token;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const getProfile = async (token) => {
  try {
    const response = await Axios.get(apiBaseUrl + "profile/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export { getAuthToken, getProfile };
