const searchInJson = (obj, searchTerm) => {
  if (!obj || searchTerm === "") return false;
  if (typeof obj !== "object") {
    return obj.toString().toLowerCase().includes(searchTerm.toLowerCase());
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key) && searchInJson(obj[key], searchTerm)) {
      return true;
    }
  }

  return false;
};

const highlightText = (text, highlight) => {
  const re = new RegExp(highlight, "gi");
  return text.replace(re, (match) => `<span class="highlight">${match}</span>`);
};

export { searchInJson, highlightText };
